import React, { useState } from 'react';

import { Modal, IconFilters, PrimaryButton, IconCloseFilter } from '../../../components';
import FilterComponent from '../FilterComponent';

import css from '../SearchPage.module.css';

const FiltersModal = props => {
  const [openModel, setOpenModal] = useState(false);

  const {
    onManageDisableScrolling,
    availableFilters,
    intl,
    validQueryParams,
    marketplaceCurrency,
    initialValues,
    listingCategories,
    getHandleChangedValueFn,
    isMobile,
    resetAll,
  } = props;

  const handleCloseModal = () => {
    resetAll();
    setOpenModal(false);
  };
  const handleToggleModal = () => {
    window.scrollTo(0, 0);
    setOpenModal(prevState => !prevState);
  };

  return (
    <div className={css.filterModalWrapper}>
      <PrimaryButton className={css.toggleModalBtn} onClick={handleToggleModal}>
        <IconFilters />
        {intl.formatMessage({ id: 'FiltersModal.btnLabel' })}
      </PrimaryButton>
      <Modal
        containerClassName={css.filtersModalContainerClassName}
        id="FiltersModal"
        isOpen={openModel}
        onClose={handleCloseModal}
        usePortal={!isMobile}
        onManageDisableScrolling={onManageDisableScrolling}
        hideCloseBtn
      >
        <div className={css.closeFilterWrapper}>
          {intl.formatMessage({ id: 'FilterPlain.closeFilterLabel' })}

          <button onClick={handleCloseModal}>
            <IconCloseFilter />
          </button>
        </div>
        <div className={css.filtersWrapper}>
          {availableFilters.map(filterConfig => {
            const key = `SearchFiltersDesktop.${filterConfig.scope || 'built-in'}.${
              filterConfig.key
            }`;
            return (
              <FilterComponent
                key={key}
                idPrefix="SearchFiltersDesktop"
                className={css.filter}
                config={filterConfig}
                listingCategories={listingCategories}
                marketplaceCurrency={marketplaceCurrency}
                urlQueryParams={validQueryParams}
                initialValues={initialValues}
                getHandleChangedValueFn={getHandleChangedValueFn}
                intl={intl}
                showAsPopup={false}
                liveEdit
                isDesktop
              />
            );
          })}
        </div>
        <div className={css.btnsWrapper}>
          <PrimaryButton className={css.btnClear} onClick={handleCloseModal}>
            {intl.formatMessage({ id: 'FilterPlain.clear' })}
          </PrimaryButton>
          <PrimaryButton className={css.btnDone} onClick={handleCloseModal}>
            {intl.formatMessage({ id: 'FilterPlain.done' })}
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

export default FiltersModal;
