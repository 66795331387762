import React, { useState } from 'react';
import classNames from 'classnames';

import { PrimaryButton, Modal, IconCloseFilter } from '../../../components';

import css from './SortByMobile.module.css';

const SortByIcon = props => {
  const classes = classNames(css.icon, props.className);
  return (
    <svg className={classes} width="8" height="5" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.764 4.236c.131.13.341.13.472 0l2.666-2.667a.333.333 0 10-.471-.471L4 3.528l-2.43-2.43a.333.333 0 10-.471.471l2.665 2.667z"
        fill="#4A4A4A"
        stroke="#4A4A4A"
        fillRule="evenodd"
      />
    </svg>
  );
};

const SortByMobile = props => {
  const [openModel, setOpenModal] = useState(false);
  const [openList, setOpenList] = useState(false);

  const { options, intl, onManageDisableScrolling, initialValue } = props;

  const handleCloseModal = () => setOpenModal(false);
  const handleToggleModal = () => {
    window.scrollTo(0, 0);
    setOpenModal(prevState => !prevState);
  };

  const handleShowList = () => setOpenList(prevState => !prevState);

  return (
    <div className={css.filterModalWrapper}>
      <PrimaryButton className={css.toggleModalBtn} onClick={handleToggleModal}>
        {intl.formatMessage({ id: 'MainPanelHeader.sortBy' })}
      </PrimaryButton>
      <Modal
        containerClassName={css.filtersModalContainerClassName}
        id="SortByModal"
        isOpen={openModel}
        onClose={handleCloseModal}
        usePortal={false}
        onManageDisableScrolling={onManageDisableScrolling}
        hideCloseBtn
      >
        <div className={css.closeFilterWrapper}>
          {intl.formatMessage({ id: 'MainPanelHeader.sortBy' })}

          <button onClick={handleCloseModal}>
            <IconCloseFilter />
          </button>
        </div>
        <div className={css.content}>
          <div className={css.btnSortByWrapper}>
            <PrimaryButton className={css.btnSortBy} onClick={handleShowList}>
              {intl.formatMessage({ id: 'MainPanelHeader.sortBy' })}
              <SortByIcon />
            </PrimaryButton>
          </div>
          {openList ? (
            <ul className={css.filtersWrapper}>
              {options.map(option => {
                const selected = initialValue === option.key;
                const menuItemBorderClass = selected
                  ? css.menuItemBorderSelected
                  : css.menuItemBorder;

                return (
                  <li key={option.key} className={css.listItem}>
                    <button className={css.menuItem} disabled={option.disabled} onClick={() => {}}>
                      <span className={menuItemBorderClass} />
                      {option.longLabel || option.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
        <div className={css.btnsWrapper}>
          <PrimaryButton className={css.btnApply} onClick={handleCloseModal}>
            {intl.formatMessage({ id: 'MainPanelHeader.applyBtn' })}
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

export default SortByMobile;
